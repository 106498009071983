<template>
  <div
    ref="container"
    class="marquee"
    :class="{
      reset: reset,
      visible: repeat !== 0
    }"
  >
    <div
      class="text-wrapper"
      ref="wrapper"
    >
      <span ref="text"><slot/></span>
      <span v-for="i in repeat" :key="i"><slot/></span>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import WaitLoader from '@/mixins/wait-loader'

export default {
  name: 'MarqueeText',
  mixins: [WaitLoader(1500)],
  data () {
    return {
      repeat: 0,
      reset: true
    }
  },
  async mounted () {
    await this.$nextTick()
    this.onResize()
    this.createTl()
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      clearTimeout(this.timeout)
      const containerW = this.$refs.container.offsetWidth
      this.spanW = this.$refs.text.offsetWidth

      this.reset = true
      this.repeat = 3 + ~~(containerW / this.spanW)
      this.timeout = setTimeout(() => (this.reset = false), 500)
    },
    createTl () {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1
        }
      })
      this.checkLoaderState()
      this.tl.fromTo(this.$refs.wrapper, { x: this.spanW * -0.5 }, { x: this.spanW * -1.5, duration: 1, overwrite: 'auto', immediateRender: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.marquee {
  overflow: hidden;

  font-size: 0;
  opacity: 0;
  transition: opacity 0.2s;
  white-space: nowrap;
}

.text-wrapper {
  will-change: transform;
}

.visible {
  opacity: 1;
}

span {
  display: inline-block;
}
</style>
