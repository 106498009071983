<template>
  <div>
    <HeroText title="Fedrigoni Top Awards" subtitle="Discover Projects" />
    <div class="container info-section mb-8">
      <div class="row">
        <div class="col-md-6 mb-4 md:mb-0">
          <h3 class="label mb-2 stagger">{{$root.Settings.projectsTitle}}</h3>
          <MarkdownBlock
            class="stagger"
            :text="$root.Settings.projectsDescription"
          />
        </div>
        <div class="col-md-6 md:flex md:items-end md:justify-end">
          <h3 class="list-anchor label stagger">{{$root.Settings.projectsListTitle}}</h3>
        </div>
      </div>
    </div>
    <template v-if="filteredProjects">
      <transition-group name="flip-list" tag="ul">
        <li
          v-for="(project, i) in filteredProjects"
          :key="project.id"
          class="list-item"
        >
          <ProjectResume
            v-bind="project"
            :index="i"
          />
        </li>
      </transition-group>
    </template>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { MarkdownBlock } from '@monogrid/vue-lib'
import ShareMixin from '@/mixins/share-mixin'
import WaitLoader from '@/mixins/wait-loader'
import ProjectResume from '@/molecules/ProjectResume'
import HeroText from '@/atoms/HeroText'

export default {
  name: 'ProjectsListPage',
  mixins: [ShareMixin, WaitLoader(1000)],
  components: {
    MarkdownBlock,
    HeroText,
    ProjectResume
  },
  data () {
    return {
      filterOpen: false,
      filters: []
    }
  },
  async mounted () {
    await this.$nextTick()
    this.createTl()
    this.$events.on('change:projects-filter', this.onFiltersChange)
    this.$events.on('scroll', this.onScroll)
  },
  beforeDestroy () {
    this.$events.off('change:projects-filter', this.onFiltersChange)
    this.tl && this.tl.kill()
    clearTimeout(this.timeout)
  },
  watch: {
    '$root.Projects': {
      immediate: true,
      handler (value) {
        if (value) {
          this.$root.preloader.visible = false
        }
      }
    }
  },
  computed: {
    filteredProjects () {
      if (this.filters.length === 0) return this.$root.Projects
      return this.$root.Projects.filter(({ hp, category }) => {
        return this.filters.find(cat => {
          return cat === 'hp' ? hp : category.id.indexOf(cat) === 0
        })
      })
    }
  },
  methods: {
    async onFiltersChange (filters) {
      this.filters = filters
      this.disableAutoClose = true
      clearTimeout(this.timeout)

      gsap.to(window, { duration: 0.5, scrollTo: { y: '.list-anchor', offsetY: window.innerWidth > 767 ? 200 : 60, autoKill: false }, ease: 'power2' })

      this.timeout = setTimeout(() => {
        ScrollTrigger.refresh(true)
        this.disableAutoClose = false
      }, 1000)
    },
    createTl () {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el.querySelector('.info-section'),
          start: 'bottom bottom'
        }
      })
      this.checkLoaderState()
      this.tl.fromTo(this.$el.querySelectorAll('.info-section .stagger'), { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5 })
    }
  },
  metaInfo () {
    if (this.$root.Settings) {
      return this.getShare({
        title: this.$root.Settings.projectsTitle,
        description: this.$root.Settings.projectsDescription
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  overflow: hidden;
  max-height: rem(300px);
}

.flip-list-move {
  transition: transform 0.5s;
}

.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 0.5s;
}

.flip-list-enter,
.flip-list-leave-to {
  max-height: 0;

  opacity: 0;
}
</style>
