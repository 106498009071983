<template>
  <router-link
    class="project pb-12 md:pb-20"
    :class="{
      'palette-Labels': category && category.colorPalette === 'Labels',
      'palette-Packaging': category && category.colorPalette === 'Packaging',
      'palette-Publishing': category && category.colorPalette === 'Publishing',
      'palette-CorporateIdentity': category && category.colorPalette === 'CorporateIdentity',
    }"
    :to="{name: 'SingleProject', params: {slug: _slug}}"
    @mousemove.native="onMouseMove"
    @mouseenter.native="onMouseEnter"
    @mouseleave.native="onMouseLeave"
  >
  <div class="bg"></div>
  <template v-if="image">
    <div
      v-for="i in 3"
      :key="i"
      class="image"
      ref="images"
      :style="{'z-index': -i}"
    >
      <ResponsiveImage
        :data="image"
        :width="400"
        role="presentation"
      />
    </div>
  </template>
    <div class="container">
      <div class="line mb-10 md:mb-16"></div>
      <h4 class="mb-6">
        <!-- <span class="index pr-4 md:pr-16">{{projectIndex}}</span>  -->
        <span class="text-primary">{{ category ? category.title : ''}}</span>
      </h4>
    </div>
    <MarqueeText ref="marquee"> <span class="h3 marquee-text">{{title}}</span></MarqueeText>
    <Badge
      v-if="$root.Settings.badges && hp"
      ref="badgeHp"
      type="hp"
      :style="badgeStyleHp"
      class="project-badge hp"
    />
    <Badge
      v-if="$root.Settings.badges && category && badge"
      ref="badge"
      :category="category.colorPalette"
      :type="badge"
      :style="badgeStyle"
      class="project-badge text-secondary"
    />
  </router-link>
</template>

<script>
import { gsap } from 'gsap'
import WaitLoader from '@/mixins/wait-loader'
import MarqueeText from '@/atoms/MarqueeText'
import ResponsiveImage from '@/atoms/ResponsiveImage'
import Badge from '@/atoms/Badge'

export default {
  name: 'ProjectResume',
  inheritAttrs: false,
  mixins: [WaitLoader(1500)],
  props: {
    _slug: { type: String },
    title: { type: String },
    badge: { type: String },
    hp: { type: Boolean },
    index: { type: Number },
    category: { type: Object },
    image: { type: Object }
  },
  components: { MarqueeText, ResponsiveImage, Badge },
  data () {
    return {
      badgeStyle: {
        transform: `translate(-${~~(40 * Math.random())}%, -${50 + ~~(12 - 24 * Math.random())}%)`
      },
      badgeStyleHp: {
        transform: `translate(-${(~~(40 * Math.random())) + (this.badge ? 50 : 0)}%, -${50 + ~~(12 - 24 * Math.random())}%)`
      }
    }
  },
  async mounted () {
    this.lastX = 0
    this.lastY = 0
    await this.$nextTick()
    this.createTl()
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
    this.tlBg && this.tlBg.kill()
  },
  computed: {
    projectIndex () {
      return (this.index + 1 + '').padStart(2, '0')
    }
  },
  methods: {
    createTl () {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'center bottom'
        },
        onComplete: () => {
          this.isReady = true
          this.$el.classList.add('ready')
        }
      })
      this.checkLoaderState()
      this.tl.fromTo(this.$el.querySelector('.line'), { scaleX: 0 }, { scaleX: 1, duration: 0.5 })
      this.tl.fromTo(this.$el.querySelectorAll('h4 span'), { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5 }, 0.1)
      this.tl.fromTo(this.$refs.marquee.$el, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5 }, 0.2)
      if (this.$refs.badgeHp) {
        this.tl.fromTo(this.$refs.badgeHp.$el, { scale: 0.8, rotation: -25, autoAlpha: 0 }, { scale: 1, rotation: 0, autoAlpha: 1, duration: 0.5 }, 0.3)
      }
      if (this.$refs.badge) {
        this.tl.fromTo(this.$refs.badge.$el, { scale: 0.8, rotation: -25, autoAlpha: 0 }, { scale: 1, rotation: 0, autoAlpha: 1, duration: 0.5 }, 0.4)
      }
      if (this.$root.platform.desktop) {
        this.tlBg = gsap.timeline({ paused: true })
        this.tlBg.addLabel('exit-bottom')
        this.tlBg.fromTo(this.$el.querySelector('.bg'), { scaleY: 0 }, { scaleY: 1, transformOrigin: 'bottom center', ease: 'power2.inout', duration: 0.35 })
        this.tlBg.addLabel('hover')
        this.tlBg.to(this.$el.querySelector('.bg'), { scaleY: 0, transformOrigin: 'top center', ease: 'power2.inout', duration: 0.35 })
        this.tlBg.addLabel('exit-top')
      }
    },
    onMouseEnter (e) {
      if (!this.$root.platform.desktop) return
      const rect = e.currentTarget.getBoundingClientRect()
      const x = e.clientX - rect.left // x position within the element.
      const y = e.clientY - rect.top - rect.height / 2 // y position within the element.
      this.$refs.images.forEach((image, i) => {
        gsap.set(image, { x, y })
      })

      if (this.tlBg) {
        if (this.tlBg.isActive()) {
          this.tlBg.tweenTo('hover')
        } else {
          this.tlBg.tweenFromTo(y > 0 ? 'exit-bottom' : 'exit-top', 'hover')
        }
      }
    },
    onMouseLeave (e) {
      if (!this.$root.platform.desktop) return
      const rect = e.currentTarget.getBoundingClientRect()
      const y = e.clientY - rect.top - rect.height / 2 // y position within the element.
      if (this.tlBg) {
        this.tlBg.tweenTo(y > 0 ? 'exit-bottom' : 'exit-top')
      }
    },
    onMouseMove (e) {
      if (!this.$root.platform.desktop) return
      if (!this.isReady) return
      const rect = e.currentTarget.getBoundingClientRect()
      const x = e.clientX - rect.left // x position within the element.
      const y = e.clientY - rect.top - rect.height / 2 // y position within the element.
      this.$refs.images.forEach((image, i) => {
        gsap.to(image, { x, y, duration: 0.2 * (i + 1), overwrite: 'auto' })
        gsap.to(image, { autoAlpha: 1, duration: 0.2 * (i + 1), overwrite: 'auto' })
      })
      clearTimeout(this.hoverTimeout)
      this.hoverTimeout = setTimeout(() => {
        this.$refs.images.forEach((image, i) => {
          gsap.to(image, { autoAlpha: 0, duration: 0.2, overwrite: true })
        })
      }, 400)
    }
  }
}
</script>

<style lang="scss" scoped>

.project {
  position: relative;

  display: block;

  transition: background-color 0.3s, color 0.5s;

  &:not(.ready) {
    pointer-events: none;
  }

}

.bg {
  position: absolute;
  z-index: -4;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // opacity: 0.5;
  transform: scaleY(0);
  will-change: transform;
}

.project-badge {
  position: absolute;
  z-index: 2;
  top: 35%;
  right: 0%;

  transform: translateY(-50%);

  @screen md {
    top: 50%;
    right: 5%;
  }

  &.hp {
    color: $c-hp;
  }
}

.container {
  position: relative;
}

.image {
  position: absolute;
  left: rem(-175px);

  display: none;

  width: rem(350px);

  opacity: 0;
  pointer-events: none;

  html.desktop & {
    display: block;

    will-change: transform;
  }
}

.line {
  @include themify-rule('background-color', 'text', 'base');
  width: 100%;
  height: 1px;

  transform: scaleX(0);
  transition: background-color 0.3s 0.1s;

  html[data-theme='dark'] & {
    @include themify-rule('background-color', 'text', 'base-dark');
  }
}

.index {
  font-size: rem(45px);
  font-weight: 500;
}

.marquee-text {
  padding-right: rem(64px);
  padding-left: rem(64px);

  html.desktop & {
    padding-right: rem(200px);
    padding-left: rem(200px);
  }
}

@each $cat, $palette in $page-types {
  .palette-#{$cat} {
    .text-primary {
      @include themify-rule('color', 'bg', 'cat-'+$palette);
      transition-delay: 0s;
    }

    .text-secondary {
      @include themify-rule('color', 'secondary', 'cat-'+$palette);
      transition-delay: 0s;
    }

    .bg {
      @include themify-rule('background-color', 'bg', 'cat-'+$palette);
    }

    html.desktop &.ready:hover {
      @include themify-rule('color', 'text', 'cat-'+$palette);

      .text-primary {
        @include themify-rule('color', 'text', 'cat-'+$palette);
      }

      // .line {
      //   @include themify-rule('background-color', 'bg', 'cat-'+$palette);
      // }
    }
  }

  html[data-theme='dark'] .palette-Publishing .text-primary {
    color: #763e9a;
  }
}

</style>
